<template>
  <div>
    <div class="pa-4 d-flex align-center">
      <h2>
        Formulários
      </h2>
      <v-spacer/>
      <FormFilter v-model="filters" />
      <v-btn @click="$refs.newFormDialog.open('requested')" color="secondary">
        Novo Formulário
      </v-btn>
    </div>
    <v-divider />
    <v-data-table
      :height="contentHeight-69"
      :loading="loading"
      :headers="headers"
      :items="requests"
      disable-pagination
      loading-text="Carregando... Por favor, aguarde"
      hide-default-footer
      fixed-header
      style="cursor: pointer"
      @click:row="$refs.requestDialog.open($event.id)"
    >
      <template #no-data>
        <div v-if="tryAgain" class="py-5 d-flex flex-column align-center black--text">
          Erro ao carregar formulários
          <br/>
          <v-btn small depressed color="error" class="mt-4" @click="tryAgain = false">
            tentar novamente
          </v-btn>
        </div>
        <template v-else>
          Nenhum formulário encontrado
        </template>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          label
          small
          :color="getStatusColor(item)"
          style="width: 120px"
          class="justify-center align-center font-weight-medium"
        >
          {{ getStatusText(item) }}
          <template v-if="item.status == 'pending'">
            {{ item.current_question + "/" + item.total_questions }}
          </template>
        </v-chip>
      </template>

      <template v-slot:[`item.creation_datetime`]="{ item }">
        {{ $utils.formatDateTime(item.creation_datetime) }}
      </template>

      <template v-slot:[`item.categories`]="{ item }">
        <DocCategoryChips :categories="item.categories" />
      </template>

      <template #[`body.append`]>
        <tr v-if="!fullyLoaded && !tryAgain && started">
          <td :colspan="headers.length">
            <scroll-loader
              :loader-method="loadRequests"
              :loader-disable="fullyLoaded"
            >
              <v-progress-circular
                indeterminate
                :size="20"
                :width="2"
              />
            </scroll-loader>
          </td>
        </tr>
      </template>

    </v-data-table>
    <RequestDialog
      ref="requestDialog"
      @updated="resetLoader()"
    />
    <NewFormDialog
      ref="newFormDialog"
      @updated="resetLoader()"
      @created="$refs.requestDialog.open($event)"
    />
  </div>
</template>

<script>
import RequestDialog from "@/components/form_requests/RequestDialog.vue";
import NewFormDialog from "@/components/form_requests/NewFormDialog.vue";
import DocCategoryChips from '@/components/documents/DocCategoryChips.vue';
import FormFilter from '@/components/form_requests/FormFilter.vue';


export default {
  name: "FormRequests",
  components: {
    RequestDialog,
    NewFormDialog,
    DocCategoryChips,
    FormFilter,
  },

  props: ['contentHeight'],

  data: () => ({
    started: false,
    fullyLoaded: false,
    loading: false,
    tryAgain: false,
    requests: [],
    filters: {},
    changeTimeout: undefined,
    limit: 30,
    headers: [
      {
        text: "Cliente",
        value: "client.name",
        sortable: false
      },
      { 
        text: "Titulo",
        value: "title",
        sortable: false
      },
      { 
        text: "Criado em",
        value: "creation_datetime",
        sortable: false
      },
      {
        text: "Categorias",
        value: "categories",
        sortable: false
      },
      {
        text: "Status",
        value: "status",
        sortable: false
      },
    ],
  }),

  methods: {
    resetLoader() {
      this.started = true;
      if (this.changeTimeout != undefined) {
        clearTimeout(this.changeTimeout);
      }
      this.changeTimeout = setTimeout(() => {
        if (this.loading) {
          this.resetLoader();
        } else {
          this.fullyLoaded = false;
          this.$set(this, "requests", []);
        }
      }, 300);
    },
    reload(){
      if (this.started){
        this.resetLoader();
      } else {
        this.started = true;
      }
    },
    async loadRequests() {
      this.loading = true;
      this.tryAgain = false;
      try {
        const response = await this.$axios.patch(
          `/form_requests`,
          this.filters,
          {
            params: {
              limit: this.limit,
              offset: this.requests.length,
            },
          }
        );
        this.requests = [...this.requests, ...response.data];
        if (response.data.length < this.limit) {
          this.fullyLoaded = true;
        }
      } catch (error){
        this.tryAgain = true;
        this.$showMessage("error", "Erro ao carregar as Requisições");
      } finally {
        this.loading = false;
      }
    },
    getStatusColor(item) {
      switch (item.status){
        case null: return "yellow darken-2";
        case "expired": case "canceled": return "grey lighten-2";
        case "answered": return "green lighten-3";
        case "pending": return "warning";
      }
      return 'grey';
    },
    getStatusText(item) {
      switch (item.status){
        case null: return "Modelo";
        case 'canceled': return "Cancelado";
        case 'answered': return "Respondido";
        case 'deleted': return "Excluído";
        case 'expired': return "Expirado";
        case 'pending': return "Pendente";
      }
    },
  },
  watch: {
    filters: {
      deep: true,
      handler(){
        this.resetLoader();
      },
    }
  },
};
</script>

<style >
tbody tr:nth-of-type(odd) {
  background-color: rgba(5, 96, 124, 0.03);
}
</style>